<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_config.training_category') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col v-if="$store.state.Auth.activeRoleId === 1" lg="6" md="6" sm="12" xs="12">
            <!-- <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.organization')"
              label-for="training_type_id"
            >
            <b-form-select
                plain
                v-model="search.org_id"
                :options="organizationtList"
                id="org_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group> -->
            <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="org_id"
                  >
                  <template v-slot:label>
                    {{ $t('globalTrans.organization')}}
                  </template>
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= organizationtList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <!-- <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('elearning_config.training_type')"
              label-for="training_type_id"
            >
            <b-form-select
                plain
                v-model="search.training_type_id"
                :options="trainingTypeList"
                id="training_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group> -->
            <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="training_type_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.training_type')}}
                  </template>
                  <v-select name="training_type_id"
                    v-model="search.training_type_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= trainingTypeList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="5"
              :label="$t('elearning_config.training_category')"
              label-for="training_category"
            >
              <b-form-input
                id="training_category"
                v-model="search.training_category"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_config.training_category_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(training_type)="data">
                      <span class="capitalize">{{ data.item.training_type }}</span>
                    </template>
                    <template v-slot:cell(training_category)="data">
                      <span class="capitalize">{{ data.item.training_category }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_view" title="Training Category Modify" v-b-modal.modal-4 variant=" iq-bg-success mr-1 border" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)">
                            <i class="fas fa-toggle-on"></i>
                        </a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-else @click="remove(data.item)">
                            <i class="fas fa-toggle-off"></i>
                        </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCategoryList, trainingCategoryToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        training_type_id: '',
        training_category: '',
        org_id: '',
        trainingTypeList: []
      },
      trainingTypeList: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    trainingTypeListAll () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_config.training_category') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.training_category') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('elearning_config.training_type'), class: 'text-center' },
          { label: this.$t('elearning_config.training_category'), class: 'text-center' },
          { label: this.$t('elearning_config.remarks'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_bn' },
          { key: 'training_type_bn' },
          { key: 'training_category_bn' },
          { key: 'remarks_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_en' },
          { key: 'training_type' },
          { key: 'training_category' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, trainingCategoryToggleStatus, item, 'training_e_learning', 'trainingCategoryList')
      // this.changeStatus(trainingElearningServiceBaseUrl, trainingCategoryToggleStatus, item)
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, trainingCategoryList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(law => law.value === parseInt(item.training_type_id))
        const trainingTypeData = {}
        if (typeof trainingTypeObj !== 'undefined') {
          trainingTypeData.training_type = trainingTypeObj.text_en
          trainingTypeData.training_type_bn = trainingTypeObj.text_bn
        } else {
          trainingTypeData.training_type = ''
          trainingTypeData.training_type_bn = ''
        }
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(law => law.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org_en = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org_en = ''
          orgData.org_bn = ''
        }
        return Object.assign({}, item, trainingTypeData, orgData)
      })
      return listData
    }
  }
}
</script>
